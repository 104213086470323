import { ExtraOptions, RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { NgxAuthComponent } from './@theme/components/auth/auth.component';
// import { NgxAuthBlockComponent } from './@theme/components/auth/auth-block/auth-block.component';
import { NgxLoginComponent } from './@theme/components/auth/login/login.component';
import { NgxAuthenticatedComponent } from './@theme/components/auth/authenticated/authenticated.component';
// import { NgxRegisterComponent } from './@theme/components/auth/register/register.component';
import { NgxSignupFormComponent } from './@theme/components/auth/signup-form/signup-form.component';
import { RegisterFiraComponent } from './@theme/components/auth/register-fira/register-fira.component';
import { NgxLogoutComponent } from './@theme/components/auth/logout/logout.component';
import { NgxRequestPasswordComponent } from './@theme/components/auth/request-password/request-password.component';
import { NgxResetPasswordComponent } from './@theme/components/auth/reset-password/reset-password.component';
import { RedirectComponent } from './redirect/redirect.component';

import { AdminRouteGuard } from './admin-route-guard';
import { UserRouteGuard } from './user-route-guard';

const routes: Routes = [
  {
    path: 'redirect',
    component: RedirectComponent,
  },
  {
    path: 'user',
    loadChildren: 'app/user/user.module#UserModule',
    canActivate: [ UserRouteGuard ],
  },
  {
    path: 'admin',
    loadChildren: 'app/pages/pages.module#PagesModule',
    canActivate: [ AdminRouteGuard ],
  },
  {
    path: 'auth',
    component: NgxAuthComponent,
    children: [
      {
        path: '',
        component: NgxLoginComponent,
      },
      {
        path: 'login',
        component: NgxLoginComponent,
      },
      {
        path: 'authenticated',
        component: NgxAuthenticatedComponent,
      },
      {
        path: 'logout',
        component: NgxLogoutComponent,
      },
      {
        path: 'request-password',
        component: NgxRequestPasswordComponent,
      },
      {
        path: 'reset-password',
        component: NgxResetPasswordComponent,
      },
    ],
  },
  { path: '', redirectTo: 'auth', pathMatch: 'full' },
  // { path: '**', redirectTo: '/admin/dashboard' },
  {
    path: 'signup',
    component: NgxSignupFormComponent,
  },
  {
    path: 'register',
    component: RegisterFiraComponent,
  }
];

const config: ExtraOptions = {
  useHash: true,
  onSameUrlNavigation: 'reload'
};

@NgModule({
  imports: [
    RouterModule.forRoot(routes, config),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
