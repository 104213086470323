import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';
declare var Swal: any;

@Component({
  selector: 'register-fira',
  templateUrl: './register-fira.component.html',
  styleUrls: ['./register-fira.component.scss']
})
export class RegisterFiraComponent implements OnInit {

  aceptoTerminos = false;

  clvServicio: string | null = null;
  sessionState: string | null = null;
  code: string | null = null;

  response: any = [];
  user: any = [];

  password: any = '';

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private router: Router
    ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      this.clvServicio = params['clv_servicio'];
      this.sessionState = params['session_state'];
      this.code = params['code'];
      console.log('clvServicio:', this.clvServicio);
      console.log('sessionState:', this.sessionState);
      console.log('code:', this.code);

      // if(this.code){
        this.sendRequest();
      // }

    });
  }

  goBack(): void {
    window.history.back();
  }

  sendRequest(): void {

    let requestData = {
      url: "https://server.sinecta.com/api/v1/agritech_fira/register",
      body: {
        tokencode: this.code,
      },
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    };

    const headers = new HttpHeaders(requestData.headers);
    const body = new URLSearchParams(requestData.body as any).toString();

    this.http.post(requestData.url, body, { headers })
      .subscribe(
        (response:any):any => {
          this.response = response;
          this.user = this.response.userPrintData;

          if(this.user.userRegister){
            Swal.fire({
              title: '',
              text: 'El usuario ya se encuentra registrado, redireccionar al login',
              icon: 'success',
              allowOutsideClick: false
            }).then(() => {
              // Redirecciona al login usando el Router de Angular
              window.location.href = response.redirectUrl + '' + response.newToken  // Usa la ruta de tu login
            });
          }else{
            const spinner = document.getElementById('nb-global-spinner');
            if (spinner) {
              spinner.style.display = 'none'; // Ocultar el spinner
            }
          }
          
        },
        error => {
          Swal.fire({
            title: '',
            text: error.error.mensaje,
            icon: 'warning',
            allowOutsideClick: false
          }).then(() => {
            this.router.navigate(['/auth']);
          });

        }
      );
  }

  goLoginOPS(){
    window.location.href = 'https://operaciones.sinecta.com/'
  }

  onSubmit(procede): void {
    console.log('Form submitted');

    if(this.password !== '' && this.password.length < 6){
      Swal.fire({
        title: '',
        text: 'La contraseña debe de ser de al menos 6 dígitos',
        icon: 'warning',
        allowOutsideClick: false
      })
    }

    Swal.fire({
      title: '',
      html: 'Procesando...',
      allowOutsideClick: false,
      didOpen: () => { Swal.showLoading() },
    });

    this.response.userPrintData = JSON.stringify(this.response.userPrintData);
    this.response.GetUserInfo = JSON.stringify(this.response.GetUserInfo);
    this.response.Procede = procede;
    this.response.password = this.password;

    console.log(this.response);

    let requestData = {
      url: "https://server.sinecta.com/api/v1/agritech_fira/newUser",
      body: this.response,
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    };

    const headers = new HttpHeaders(requestData.headers);
    const body = new URLSearchParams(requestData.body as any).toString();

    this.http.post(requestData.url, body, { headers })
      .subscribe(
        (response:any):any => {
          if(procede){
            Swal.fire({
              title: '',
              text: 'Usuario registrado exitosamente, redireccionar al login',
              icon: 'success',
              allowOutsideClick: false
            }).then(() => {
              // Redirecciona al login usando el Router de Angular
              window.location.href = response.UserMonitoreo.redirectUrl + '' + response.UserMonitoreo.token  // Usa la ruta de tu login
            });
          }else{
            Swal.fire({
              title: '',
              text: 'Solicitud cancelada',
              icon: 'success',
              allowOutsideClick: false
            }).then(() => {
              // Redirecciona al login usando el Router de Angular
              window.location.href = 'https://sinecta.com/';
            });
          }

          // setTimeout(() => {
          //   procede ? window.location.href = 'https://operaciones.sinecta.com/' : window.history.back();
          // }, 3000);
        },
        error => {
          Swal.fire({
            title: '',
            text: error.error.mensaje,
            icon: 'warning',
            allowOutsideClick: false
          }).then(() => {
            this.router.navigate(['/auth']);
          });

        }
      );
  }

}
