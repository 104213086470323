import { Component } from '@angular/core';

@Component({
  selector: 'app-signup-form',
  templateUrl: './signup-form.component.html',
  styleUrls: ['./signup-form.component.scss']
})
export class NgxSignupFormComponent {
  goBack(): void {
    window.history.back();
  }

  onSubmit(): void {
    console.log('Form submitted');
  }
}
